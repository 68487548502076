/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    window.$ = window.jQuery = require("jquery");
    window.Popper = require("popper.js").default;

    require("bootstrap");
} catch (e) {}

$.ajaxSetup({
    headers: {
        "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
    },
});

window.onSubmitCaptcha = function (token) {
    $("#reCaptchaForm").trigger("submit");
};

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo'

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     encrypted: true
// });

$("input[type=tel], input[inputmode=numeric]").on("input", function (e) {
    $(this).val($(this).val().replace(/[^0-9]/g, ""));
});

window.onSubmitCaptcha = function (token) {
    $("#reCaptchaForm").submit();
}